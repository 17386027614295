export default class Schrifteffekt {
  static SMALL_CAPS = 'SMALL_CAPS';

  static KURSIV = 'KURSIV';

  static FETT = 'FETT';

  static isValidState(schrifteffekt) {
    return (
      this.isSmallCaps(schrifteffekt)
      || this.isKursiv(schrifteffekt)
      || this.isFett(schrifteffekt)
    );
  }

  static isSmallCaps(schrifteffekt) {
    return schrifteffekt === this.SMALL_CAPS;
  }

  static isKursiv(schrifteffekt) {
    return schrifteffekt === this.KURSIV;
  }

  static isFett(schrifteffekt) {
    return schrifteffekt === this.FETT;
  }
}
