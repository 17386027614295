import { isJSON } from '@st/utils-js';
import { validate } from '../utils/uuid';
import ElementAttrs from './ElementAttrs';
import UserRolle from './enums/UserRolle';
import Responsibilities from './Responsibilities';

const REQUIRED_ROLES = [
  UserRolle.RECHTSANWALT,
  UserRolle.PARTNER,
  UserRolle.ADMINPARTNER
];

export default class AbschnittKlauselTransformationCheckliste extends ElementAttrs {
  /**
   *
   * @param {String} name
   * @param {Array} hashtags Array of UUIDs
   * @param {Responsibilities} responsibilities
   * @param {String} choice
   * @param {Boolean} questionsSet
   * @param {Boolean} phrasesAreSet
   * @param {Boolean} textIsComplete
   * @param {Boolean} commentsAreEdited
   * @param {Boolean} placeholderAreSet
   * @param {Boolean} referencesAreComplete
   * @param {String} externalExplanations
   * @param {String} internalExplanations
   * @param {String} preFormulatedQuestion
   */
  constructor(
    name = '',
    hashtags = [],
    responsibilities = new Responsibilities(),
    choice = [],
    questionsSet = false,
    phrasesAreSet = false,
    textIsComplete = false,
    commentsAreEdited = false,
    placeholderAreSet = false,
    referencesAreComplete = false,
    externalExplanations = '',
    internalExplanations = '',
    preFormulatedQuestion = ''
  ) {
    super(name, hashtags, responsibilities, REQUIRED_ROLES);
    this.questionsSet = questionsSet;
    this.choice = choice;
    this.phrasesAreSet = phrasesAreSet;
    this.textIsComplete = textIsComplete;
    this.commentsAreEdited = commentsAreEdited;
    this.placeholderAreSet = placeholderAreSet;
    this.referencesAreComplete = referencesAreComplete;
    this.externalExplanations = externalExplanations;
    this.internalExplanations = internalExplanations;
    this.preFormulatedQuestion = preFormulatedQuestion;
  }

  /**
   * Creates AbschnittKlauselTransformationCheckliste from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value) {
    if (!isJSON(value)) return null;

    const {
      name = '',
      hashtags = [],
      responsibilities = new Responsibilities(),
      choice = [],
      questionsSet = false,
      phrasesAreSet = false,
      textIsComplete = false,
      commentsAreEdited = false,
      placeholderAreSet = false,
      referencesAreComplete = false,
      externalExplanations = '',
      internalExplanations = '',
      preFormulatedQuestion = ''
    } = value;

    let res = responsibilities;
    if (!(res instanceof Responsibilities) && isJSON(res)) {
      res = Responsibilities.fromJSON(res);
    }

    return new AbschnittKlauselTransformationCheckliste(
      name,
      hashtags,
      res,
      choice,
      questionsSet,
      phrasesAreSet,
      textIsComplete,
      commentsAreEdited,
      placeholderAreSet,
      referencesAreComplete,
      externalExplanations,
      internalExplanations,
      preFormulatedQuestion
    );
  }

  /**
   * @returns AbschnittKlauselTransformationCheckliste in JSON representation
   */
  toJSON() {
    let responsibilities = {};
    if (this.responsibilities instanceof Responsibilities) {
      responsibilities = this.responsibilities.toJSON();
    } else {
      responsibilities = this.responsibilities;
    }

    return {
      name: this.name,
      hashtags: this.hashtags,
      responsibilities,
      choice: this.choice,
      questionsSet: this.questionsSet,
      phrasesAreSet: this.phrasesAreSet,
      textIsComplete: this.textIsComplete,
      commentsAreEdited: this.commentsAreEdited,
      placeholderAreSet: this.placeholderAreSet,
      referencesAreComplete: this.referencesAreComplete,
      externalExplanations: this.externalExplanations,
      internalExplanations: this.internalExplanations,
      preFormulatedQuestion: this.preFormulatedQuestion
    };
  }

  get validChoice() {
    const choiceIsStringAndValid = typeof this.choice === 'string'
      && (validate(this.choice) || this.choice === '');

    const choiceIsArrayAndValid = Array.isArray(this.choice)
      && this.choice.every((item) => typeof item === 'string' && validate(item));

    return choiceIsStringAndValid || choiceIsArrayAndValid;
  }

  get valid() {
    const { validName, validHashtags, validResponsibilities } = this;

    function checkBoolean(value) {
      return typeof value === 'boolean';
    }

    function checkString(value) {
      return typeof value === 'string';
    }

    const valid = validName
    && validHashtags
    && validResponsibilities
    && checkBoolean(this.questionsSet)
    && checkBoolean(this.phrasesAreSet)
    && checkBoolean(this.textIsComplete)
    && checkBoolean(this.commentsAreEdited)
    && checkBoolean(this.placeholderAreSet)
    && checkBoolean(this.referencesAreComplete)
    && this.validChoice
    && checkString(this.externalExplanations)
    && checkString(this.internalExplanations)
    && checkString(this.preFormulatedQuestion);

    return valid;
  }

  get validResponsibilities() {
    return !!this.responsibilities
      && this.responsibilities instanceof Responsibilities
      && validate(this.responsibilities[UserRolle.RECHTSANWALT])
      && validate(this.responsibilities[UserRolle.ADMINPARTNER])
      && validate(this.responsibilities[UserRolle.PARTNER])
      && !this.responsibilities[UserRolle.PRODUKTKOORDINATOR]
      && !this.responsibilities[UserRolle.MANDANT]
      && !this.responsibilities[UserRolle.SACHBEARBEITER];
  }

  get complete() {
    const { validName, validHashtags, validResponsibilities } = this;

    function checkBooleanAndTrue(value) {
      return typeof value === 'boolean' && !!value;
    }

    function checkString(value) {
      return typeof value === 'string';
    }

    const valid = validName
    && validHashtags
    && validResponsibilities
    && checkBooleanAndTrue(this.questionsSet)
    && checkBooleanAndTrue(this.phrasesAreSet)
    && checkBooleanAndTrue(this.textIsComplete)
    && checkBooleanAndTrue(this.commentsAreEdited)
    && checkBooleanAndTrue(this.placeholderAreSet)
    && checkBooleanAndTrue(this.referencesAreComplete)
    && this.validChoice
    && checkString(this.externalExplanations)
    && checkString(this.internalExplanations)
    && checkString(this.preFormulatedQuestion);

    return valid;
  }

  get completeUeberschrift() {
    const { validName, validResponsibilities } = this;

    const valid = validName
    && validResponsibilities;

    return valid;
  }
}
