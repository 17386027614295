import { component as MODULE } from '../store/state';

const ACTIONS = {
  FETCH_CLIENT_ELEMENT: `${MODULE}fetch_client_element`,
  FETCH_CLIENT_ELEMENTS: `${MODULE}fetch_client_elements`,

  FETCH_CLIENT_ELEMENT_LAYOUT: `${MODULE}fetch_client_element_layout`,
  SET_CLIENT_ELEMENT_LAYOUT: `${MODULE}set_client_element_layout`,
  SET_CLIENT_ELEMENT_LAYOUT_KEY_VALUE: `${MODULE}set_client_element_layout_key_value`,
  SET_CLIENT_ELEMENT_SECTION_LAYOUT: `${MODULE}set_client_element_section_layout`,
  DELETE_CLIENT_ELEMENT_SECTION_LAYOUT: `${MODULE}delete_client_element_section_layout`,

  DEPRECATE_CLIENT_ELEMENT: `${MODULE}deprecate_client_element`,

  ADD_CLIENT_ELEMENT_NEW_ELEMENT: `${MODULE}add_client_element_new_element`,
  REMOVE_CLIENT_ELEMENT_SUBELEMENT: `${MODULE}remove_client_element_subelement`,

  PUT_CLIENT_ELEMENT_METADATA: `${MODULE}put_client_element_metadata`,
  PUT_CLIENT_ELEMENT_RELEASE_FLAG: `${MODULE}put_client_element_release_flag`,
  PUT_CLIENT_ELEMENT_TAGS: `${MODULE}put_client_element_tags`,

  GET_CLIENT_ELEMENT_NAME: `${MODULE}get_client_element_name`,
  GET_CLIENT_ELEMENT_UPDATES: `${MODULE}get_client_element_updates`,
  REMOVE_CLIENT_ELEMENT_ZWANGSUPDATE_KEY: `${MODULE}remove_client_element_zwangsupdate_key`,

  GET_CLIENT_ELEMENT_ZWANGSUPDATE: `${MODULE}get_client_element_zwangsupdate`,
  SET_UPDATE_COMPLETED: `${MODULE}set_update_completed`,

  START_AENDERUNGSMODUS: `${MODULE}start_aenderungsmodus`,
  END_AENDERUNGSMODUS: `${MODULE}end_aenderungsmodus`,
  GET_AENDERUNGSMODUS_HISTORY: `${MODULE}get_aenderungsmodus_history`,
  SET_AENDERUNGSMODUS_SECTION_STATE: `${MODULE}set_aenderungsmodus_section_state`,
  PATCH_AENDERUNGSMODUS_PATCHES: `${MODULE}patch_aenderungsmodus_patches`,
  ADD_COMMENT_TO_PATCH: `${MODULE}add_comment_to_patch`,
  COMMIT_SECTION: `${MODULE}commit_section`,
  DENY_SECTION: `${MODULE}deny_section`
};

export default ACTIONS;
