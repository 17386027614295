import { isEmpty, deepClone, isJSON } from '@st/utils-js';
import ElementFactory from '../utils/ElementFactory';
import { uuid, validate } from '../utils/uuid';
import ElementStatus from './enums/ElementStatus';
import ElementTyp from './enums/ElementTyp';
import PatchTag from './enums/PatchTag';
import SubelementContentElement from './SubelementContentElement';
import TextContentElement from './TextContentElement';
import CompiledContentElement from './CompiledContentElement';

function getUsedElementIdsRecursive(editorElement, elements) {
  function getUsedElementIds(elementId, subelements, result = []) {
    result.push(elementId);
    subelements[elementId].content.forEach((child) => {
      if (child.type === 'SUBELEMENT') {
        getUsedElementIds(child.id, subelements, result);
      }
    });

    return result;
  }

  return getUsedElementIds(editorElement, elements).filter((id, i, ids) => ids.indexOf(id) === i);
}
export default class EditorElement {
  /**
   *
   * @param {String} editorElement UUID
   * @param {JSON} elementRevisions JSON with UUIDs as keys and the elementRevision as value
   * @param {JSON} elementPatchTags JSON with UUIDs as keys and the latest PatchTag of the element
   * @param {JSON} elements JSON with UUIDs as keys and the respective KlauselElement
   *  or MusterdokumentElement or BausteinElement or MustervertragElement Object
   * @param {JSON} relations JSON with UUIDs as keys and the
   *                         respective UUIDs of the related elements
   * @param {JSON} similarities JSON with UUIDs as keys and the
   *                            respective UUIDs of the similar elements
   * @param {JSON} elementUpdates JSON with UUIDs as keys and uuid of the updatedElement
   *
   * @param {JSON} compiledContent
   */
  constructor(
    editorElement = null,
    elementRevisions = {},
    elementPatchTags = {},
    elements = {},
    relations = {},
    similarities = {},
    elementUpdates = {},
    currentDocId = null
  ) {
    this.editorElement = editorElement;
    this.elementRevisions = elementRevisions;
    this.elementPatchTags = elementPatchTags;
    this.elements = elements;
    this.relations = relations;
    this.similarities = similarities;
    this.elementUpdates = elementUpdates;

    if (currentDocId) {
      this.compiledContent = EditorElement.buildCompiledContent(
        currentDocId,
        elements,
        elementRevisions,
        similarities
      );
    }
  }

  static rebuildElements(oldEE) {
    return new EditorElement(
      oldEE.editorElement,
      oldEE.elementRevisions,
      oldEE.elementPatchTags,
      oldEE.relations,
      oldEE.similarities,
      oldEE.elementUpdates,
      oldEE.compiledContent
    );
  }

  /**
   * @param {String} elementId uuid
   * @param {EditorElement} editorElement
   * @returns Array of elementIds
   */
  static getUsedElementIdsRecursive(elementId, editorElement) {
    return getUsedElementIdsRecursive(elementId, editorElement.elements);
  }

  /**
   *
   * @param {String} id UUID
   * @param {JSON} elementJSON valid JSON representation of KlauselElement,
   *  MusterdokumentElement, MustervertragElement or BausteinElement
   * @param {Number} revision
   * @param {String} patchTag value of PatchTag
   */
  addElementJSON(id, elementJSON, revision = null, patchTag = PatchTag.AUTO_SAVE) {
    const element = ElementFactory.elementFromJSON(id, elementJSON);

    this.addElement(id, element, revision, patchTag);
  }

  /**
   *
   * @param {String} id UUID
   * @param {KlauselElement
   * | MusterdokumentElement
   * | BausteinElement
   * | MustervertragElement} element
   * @param {Number} revision
   * @param {String} patchtag value of PatchTag
   */
  addElement(id, element, revision = 0, patchTag = PatchTag.AUTO_SAVE) {
    this.elementRevisions[id] = revision;
    this.elementPatchTags[id] = patchTag;
    const cleanElement = deepClone(element.toJSON());
    delete cleanElement.id;
    delete cleanElement.revision;
    this.elements[id] = cleanElement;
  }

  /**
   *
   * @param {String} id UUID
   * @param {Boolean} clean default is false. If true it removes all subelements recursively.
   */
  removeElement(id, clean = false) {
    if (id === this.editorElement) return;

    let relatedIds = null;

    if (!clean) {
      relatedIds = [ id ];
    } else {
      const usedElementIds = EditorElement.getUsedElementIdsRecursive(id, this);

      Object.values(this.elements)
        .filter((element) => !usedElementIds.includes(element.id)
          && element.id !== this.editorElement)
        .forEach((element) => {
          const filtered = element.content
            .filter((subelement) => subelement instanceof SubelementContentElement
              && subelement.id !== id);

          const hasId = filtered
            .some((subelement) => usedElementIds.includes(subelement.id));

          if (hasId) {
            usedElementIds.splice(usedElementIds.indexOf(element.id), 1);
          }
        });

      relatedIds = usedElementIds;
    }

    relatedIds.forEach((elementId) => {
      delete this.elementRevisions[elementId];
      delete this.elementPatchTags[elementId];
      delete this.elements[elementId];
      delete this.relations[elementId];
    });
  }

  /**
   * Creates EditorElement from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @param {String} currentDocId
   * @returns null if invalid
   */
  static fromJSON(value, currentDocId) {
    if (!isJSON(value)) return null;

    const {
      editorElement = null,
      elementRevisions = {},
      elementPatchTags = {},
      elements = {},
      relations = {},
      similarities = {},
      elementUpdates = {}
    } = value;

    return new EditorElement(
      editorElement,
      elementRevisions,
      elementPatchTags,
      elements,
      relations,
      similarities,
      elementUpdates,
      currentDocId
    );
  }

  static buildCompiledContent(
    currentDocId,
    elements,
    elementRevisions,
    similarities = {},
    ancestors = []
  ) {
    if (!currentDocId || isEmpty(elements) || !elements[currentDocId]) return [];

    const compiledContent = elements[currentDocId].content.reduce((acc, element) => {
      let tmpElement = element;
      const tmpAncestors = [ ...ancestors ];
      const tmpSectionSimilarities = similarities[tmpElement.id] || [];

      const elemIsSubelement = ElementTyp.isSubelement(tmpElement.type);
      const elemExists = elemIsSubelement && elements[tmpElement.id];

      if (elemIsSubelement && !elemExists) {
        tmpAncestors.push({
          id: tmpElement.id,
          type: ElementTyp.KLAUSEL,
          refPathId: tmpElement.refPathId,
          choice: tmpElement.choice || []
        });

        tmpElement = (new TextContentElement()).toJSON();

        tmpElement.content = [
          {
            type: 'paragraph',
            content: [
              {
                marks: [ { type: 'bold' } ],
                text: 'Die Klausel wurde gelöscht.',
                type: 'text'
              }
            ]
          }
        ];

        tmpElement.attrs.alertType = 'deleted';
      }

      if (elemIsSubelement && elemExists) {
        const origin = elements[tmpElement.id];
        const subElementAncestor = [
          ...ancestors,
          {
            id: tmpElement.id,
            type: origin.type,
            refPathId: tmpElement.refPathId,
            choice: tmpElement.choice || []
          }
        ];
        const result = EditorElement.buildCompiledContent(
          tmpElement.id,
          elements,
          elementRevisions,
          similarities,
          subElementAncestor
        );
        const mapped = result.map(({
          refElement,
          refPathId,
          ancestors: resultAncestors
        }) => (CompiledContentElement.fromJSON({
          refElement,
          ancestors: resultAncestors,
          refPathId,
          similarities: tmpSectionSimilarities,
          elementRevision: elementRevisions[tmpElement.id]
        })));
        acc.push(...mapped);
        return acc;
      }

      acc.push(CompiledContentElement.fromJSON({
        refElement: tmpElement,
        refPathId: uuid(),
        ancestors: tmpAncestors,
        similarities: tmpSectionSimilarities
      }));
      return acc;
    }, []);
    return compiledContent;
  }

  /**
   * @returns EditorElement in JSON representation
   */
  toJSON() {
    return {
      editorElement: this.editorElement,
      elementRevisions: this.elementRevisions,
      elementPatchTags: this.elementPatchTags,
      elements: this.elements,
      relations: this.relations,
      similarities: this.similarities,
      elementUpdates: this.elementUpdates
    };
  }

  /**
   * Hinzufügen einer neuen Section
   *
   * @param {KlauselElement | TextContentElement } node
   * @param {String} currentDocId
   * @returns
   */
  removeNode(node, currentDocId) {
    const { refPathId, type } = node;

    const validRefPathId = validate(refPathId);
    const validId = validate(refPathId);

    if (!validRefPathId
      || !validId
    ) return null;

    const tmpElements = deepClone(this.elements);

    if (ElementTyp.isKlausel(type)) {
      // TODO entfernen von Subelementen
    }

    const filteredContent = tmpElements[currentDocId].content.filter(
      (content) => content.refPathId !== refPathId
    );

    tmpElements[currentDocId].content = filteredContent;

    return tmpElements;
  }

  /**
   * Hinzufügen einer neuen Section
   *
   * @param {Number} positionIndex
   * @param {KlauselElement | TextContentElement } node
   * @returns
   */
  // eslint-disable-next-line default-param-last
  addNodeToElement(elementId = this.editorElement, positionIndex, node) {
    const {
      sectionId: id, type, revision = 0, state = ElementStatus.MODIFIABLE
    } = node;

    const validPositionIndex = positionIndex !== undefined
      && typeof positionIndex === 'number'
      && positionIndex >= 0;

    const validId = validate(id);

    if (
      !validPositionIndex
      || !validId
    ) return;

    let newContent = null;
    if (ElementTyp.isKlausel(type)) {
      if (!this.elements[id]) {
        this.addElement(id, node, revision, state);
      }
      newContent = SubelementContentElement.createValidJSON(node);
    } else {
      newContent = TextContentElement.createValidJSON(node);
    }

    if (!newContent) return;

    this.elements[elementId].content.splice(positionIndex, 0, newContent);
  }

  get validEditorElement() {
    return !!this.editorElement
      && typeof this.editorElement === 'string'
      && validate(this.editorElement);
  }

  get validElementRevisions() {
    return isJSON(this.elementRevisions)
      && !!this.elementRevisions
      && isJSON(this.elements)
      && !!this.elements
      && Object.keys(this.elements)
        .every((elementId) => validate(elementId)
          && Object.keys(this.elementRevisions).includes(elementId))
      && Object.values(this.elementRevisions)
        .every((v) => typeof v === 'number' && v >= 0);
  }

  get validElementPatchTags() {
    return isJSON(this.elementPatchTags)
      && !!this.elementPatchTags
      && isJSON(this.elements)
      && !!this.elements
      && Object.keys(this.elements)
        .every((elementId) => validate(elementId)
          && Object.keys(this.elementPatchTags).includes(elementId))
      && Object.values(this.elementPatchTags)
        .every((v) => typeof v === 'string' && PatchTag.isTag(v));
  }

  get validElements() {
    return isJSON(this.elements)
      && !!this.elements
      && Object.keys(this.elements).length > 0
      && Object.keys(this.elements)
        .every((elementId) => typeof elementId === 'string' && validate(elementId))
      && Object.values(this.elements).every((element) => isJSON(element));
  }

  get valid() {
    const valid = this.validEditorElement
      && this.validElementRevisions
      && this.validElementPatchTags
      && this.validElements;

    return valid;
  }
}
