export default class Layout {
  static EINSPALTIGES_LAYOUT = 'einspaltigesLayout';

  static ZWEISPALTIGES_LAYOUT = 'zweispaltigesLayout';

  static FREIES_LAYOUT = 'freiesLayout';

  static isValidState(layout) {
    return (
      this.isEinspaltigesLayout(layout)
      || this.isZweispaltigesLayout(layout)
      || this.isFreiesLayout(layout)
    );
  }

  static isEinspaltigesLayout(layout) {
    return layout === this.EINSPALTIGES_LAYOUT;
  }

  static isZweispaltigesLayout(layout) {
    return layout === this.ZWEISPALTIGES_LAYOUT;
  }

  static isFreiesLayout(layout) {
    return layout === this.FREIES_LAYOUT;
  }
}
