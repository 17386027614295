export default class FusszeileSpaltenlayout {
  static EINS = 'EINS';

  static ZWEI = 'ZWEI';

  static DREI = 'DREI';

  static isValidState(fusszeileSpaltenlayout) {
    return (
      this.isEins(fusszeileSpaltenlayout)
      || this.isZwei(fusszeileSpaltenlayout)
      || this.isDrei(fusszeileSpaltenlayout)
    );
  }

  static isEins(fusszeileSpaltenlayout) {
    return fusszeileSpaltenlayout === this.EINS;
  }

  static isZwei(fusszeileSpaltenlayout) {
    return fusszeileSpaltenlayout === this.ZWEI;
  }

  static isDrei(fusszeileSpaltenlayout) {
    return fusszeileSpaltenlayout === this.DREI;
  }

  static GIRD_COLUMNS = {
    [this.EINS]: 'tw-grid-cols-1',
    [this.ZWEI]: 'tw-grid-cols-2',
    [this.DREI]: 'tw-grid-cols-3'
  };

  static AMOUNT_OF_TEXT_ROWS = {
    [this.EINS]: 1,
    [this.ZWEI]: 2,
    [this.DREI]: 3
  };
}
