export default class AbstandAbsatz {
  static AUTO = 'AUTO';

  static EINZEILIG = 'EINZEILIG';

  static EINS_KOMMA_EINS_FUENF_ZEILIG = 'EINS_KOMMA_EINS_FUENF_ZEILIG';

  static EINS_KOMMA_FUENF_ZEILIG = 'EINS_KOMMA_FUENF_ZEILIG';

  static isValidState(state) {
    const states = [
      this.AUTO, this.EINZEILIG, this.EINS_KOMMA_FUENF_ZEILIG,
      this.EINS_KOMMA_EINS_FUENF_ZEILIG
    ];
    return states.includes(state);
  }

  static isStateAuto(state) {
    return state === this.AUTO;
  }

  static isStateEinzeilig(state) {
    return state === this.EINZEILIG;
  }

  static isStateEinsKommaFuenfZeilen(state) {
    return state === this.EINS_KOMMA_FUENF_ZEILIG;
  }

  static ABSTEANDE = {
    [AbstandAbsatz.AUTO]: 'normal',
    [AbstandAbsatz.EINZEILIG]: '100%',
    [AbstandAbsatz.EINS_KOMMA_EINS_FUENF_ZEILIG]: '115%',
    [AbstandAbsatz.EINS_KOMMA_FUENF_ZEILIG]: '150%'
  };
}
