/* eslint-disable no-restricted-globals */

const { hostname } = location;
let localHostname = hostname;

if (hostname === 'designsystem.bbh-solutions.de') {
  localHostname = 'app.bbh-solutions.de';
}
if (hostname === 'lemon-designsystem.spicetech.de') {
  localHostname = 'lemon.spicetech.de';
}
if (hostname === 'lemon-designsystem-backup.spicetech.de') {
  localHostname = 'lemon-backup.spicetech.de';
}
if (hostname === 'lemon-lab.spicetech.net') {
  localHostname = 'lemon-lab-app.spicetech.net';
}
if (hostname === 'lemon-designsystem-test.spicetech.de') {
  localHostname = 'lemon-test.spicetech.de';
}

const apiUrl = `${location.protocol}//${localHostname}`;
const apiSuffix = '';
const localTomcatPort = '8080';

const mode = import.meta.env?.MODE ?? 'development';

export default {
  api: (mode === 'development' || mode === 'test')
    ? `${apiUrl}:${localTomcatPort}${apiSuffix}`
    : apiUrl + apiSuffix
};
