import { isJSON } from '@st/utils-js';
import AbstandAbsatz from './enums/AbstandAbsatz';
import Ausrichtung from './enums/Ausrichtung.js';
import KopfzeilePositionierung from './enums/KopfzeilePositionierung';
import Umrandung from './enums/Umrandung';
import Layout from './enums/Layout';
import Schrifteffekt from './enums/Schrifteffekte';
import PlatzierungDesLogos from './enums/PlatzierungDesLogos';
import RandPosition from './enums/RandPosition';
import FusszeileSpaltenlayout from './enums/FusszeileSpaltenlayout';

export default class ClientElementLayout {
  constructor(
    layout = Layout.EINSPALTIGES_LAYOUT,
    ueberschriftSchriftgroesse = 24,
    textSchriftgroesse = 12,
    ueberschriftSchriftart = 'Apex New',
    textSchriftart = 'Apex New',
    abstandOben = 30,
    abstandUnten = 30,
    abstandRechts = 20,
    abstandLinks = 20,
    abstandAbsatz = AbstandAbsatz.AUTO,
    abstandVorUeberschrift = 0,
    abstandSpalten = 4,
    textIstFett = 0,
    textIstUnterstrichen = 0,
    textIstKursiv = 0,
    ueberschriftIstFett = 0,
    ueberschriftIstUnterstrichen = 0,
    ueberschriftIstKursiv = 0,
    textSchriftFarbe = '#000000',
    ueberschriftSchriftFarbe = '#000000',
    ueberschriftHintergrundFarbe = '#FFFFFF',
    ueberschriftAbstand = AbstandAbsatz.AUTO,
    ueberschriftUnterstrichFarbe = '#000000',
    ueberschriftUnterstrichStaerke = 0,
    ueberschriftAusrichtung = Ausrichtung.LINKS,
    ueberschriftSchrifteffekte = [],

    // Kopfzeile
    logo = '',
    breiteLogo = 50,
    platzierungLogo = PlatzierungDesLogos.LEFT,
    kopfzeileText = '',
    kopfzeileTextGroesse = 12,
    kopfzeileTextFarbe = '#000000',
    kopfzeilePositionierung = KopfzeilePositionierung.ALLE_SEITEN,

    // Formular
    formularHintergrundfarbe = '#EFEFEF',
    formularRandPosition = [],
    formularRandStaerke = 0,
    formularRandRadius = 0,
    formularRandFarbe = '#FFFFFF',

    // Tabelle
    tabelleUmrandung = [ Umrandung.HORIZONTAL ],
    tabelleUmrandungStaerke = 1,
    tabelleUmrandungFarbe = '#EFEFEF',
    tabelleHintergrundFarbe = '#FFFFFF',
    tabelleKopfzeileEffekte = [],
    tabelleKopfzeileSchriftFarbe = '#000000',
    tabelleKopfHintergrundFarbe = '#FFFFFF',

    // Fusszeile
    fusszeileSpaltenlayout = FusszeileSpaltenlayout.EINS,
    fusszeileTexte = [],
    fusszeileNummerierung = Ausrichtung.RECHTS,
    fusszeileTexteGroesse = 12,
    fusszeileTexteFarbe = '#000000'
  ) {
    this.layout = layout;
    this.ueberschriftSchriftgroesse = ueberschriftSchriftgroesse;
    this.textSchriftgroesse = textSchriftgroesse;
    this.ueberschriftSchriftart = ueberschriftSchriftart;
    this.textSchriftart = textSchriftart;
    this.padding = {
      top: abstandOben,
      bottom: abstandUnten,
      right: abstandRechts,
      left: abstandLinks
    };
    if(!AbstandAbsatz.isValidState(abstandAbsatz)) {
      throw new Error(`Invalid value for abstandAbsatz: ${abstandAbsatz}`);
    }
    this.abstandAbsatz = abstandAbsatz;
    this.abstandVorUeberschrift = abstandVorUeberschrift;
    this.abstandSpalten = abstandSpalten;
    this.textIstFett = textIstFett;
    this.textIstUnterstrichen = textIstUnterstrichen;
    this.textIstKursiv = textIstKursiv;
    this.ueberschriftIstFett = ueberschriftIstFett;
    this.ueberschriftIstUnterstrichen = ueberschriftIstUnterstrichen;
    this.ueberschriftIstKursiv = ueberschriftIstKursiv;
    this.textSchriftFarbe = textSchriftFarbe;
    this.ueberschriftSchriftFarbe = ueberschriftSchriftFarbe;
    this.ueberschriftHintergrundFarbe = ueberschriftHintergrundFarbe;
    this.ueberschriftAbstand = ueberschriftAbstand;
    this.ueberschriftUnterstrichFarbe = ueberschriftUnterstrichFarbe;
    this.ueberschriftUnterstrichStaerke = ueberschriftUnterstrichStaerke;
    this.ueberschriftAusrichtung = ueberschriftAusrichtung;
    this.ueberschriftSchrifteffekte = ueberschriftSchrifteffekte;

    // Kopfzeile
    this.logo = logo;
    this.breiteLogo = breiteLogo;
    this.platzierungLogo = platzierungLogo;
    this.kopfzeileText = kopfzeileText;
    this.kopfzeileTextGroesse = kopfzeileTextGroesse;
    this.kopfzeileTextFarbe = kopfzeileTextFarbe;
    this.kopfzeilePositionierung = kopfzeilePositionierung;

    // Formular
    this.formularHintergrundfarbe = formularHintergrundfarbe;
    this.formularRandPosition = formularRandPosition;
    this.formularRandStaerke = formularRandStaerke;
    this.formularRandRadius = formularRandRadius;
    this.formularRandFarbe = formularRandFarbe;

    // Tabelle
    this.tabelleUmrandung = tabelleUmrandung;
    this.tabelleUmrandungStaerke = tabelleUmrandungStaerke;
    this.tabelleUmrandungFarbe = tabelleUmrandungFarbe;
    this.tabelleHintergrundFarbe = tabelleHintergrundFarbe;
    this.tabelleKopfzeileEffekte = tabelleKopfzeileEffekte;
    this.tabelleKopfzeileSchriftFarbe = tabelleKopfzeileSchriftFarbe;
    this.tabelleKopfHintergrundFarbe = tabelleKopfHintergrundFarbe;

    // Fusszeile
    this.fusszeileSpaltenlayout = fusszeileSpaltenlayout;
    this.fusszeileTexte = fusszeileTexte;
    this.fusszeileNummerierung = fusszeileNummerierung;
    this.fusszeileTexteGroesse = fusszeileTexteGroesse;
    this.fusszeileTexteFarbe = fusszeileTexteFarbe;

    if(!this.isValid) {
      throw new Error('Invalid ClientElementLayout');
    }
  }

  static fromJSON(value) {
    if(value instanceof ClientElementLayout) return value;
    if (!isJSON(value)) return null;

    const {
      layout,
      ueberschriftSchriftgroesse,
      textSchriftgroesse,
      ueberschriftSchriftart,
      textSchriftart,
      padding,
      abstandOben,
      abstandUnten,
      abstandRechts,
      abstandLinks,
      abstandAbsatz,
      abstandVorUeberschrift,
      abstandSpalten,
      textIstFett,
      textIstUnterstrichen,
      textIstKursiv,
      ueberschriftIstFett,
      ueberschriftIstUnterstrichen,
      ueberschriftIstKursiv,
      textSchriftFarbe,
      ueberschriftSchriftFarbe,
      ueberschriftHintergrundFarbe,
      ueberschriftAbstand,
      ueberschriftUnterstrichFarbe,
      ueberschriftUnterstrichStaerke,
      ueberschriftAusrichtung,
      ueberschriftSchrifteffekte,

      // Kopfzeile
      logo,
      breiteLogo,
      platzierungLogo,
      kopfzeileText,
      kopfzeileTextGroesse,
      kopfzeileTextFarbe,
      kopfzeilePositionierung,

      // Formular
      formularHintergrundfarbe,
      formularRandPosition,
      formularRandStaerke,
      formularRandRadius,
      formularRandFarbe,

      // Tabelle
      tabelleUmrandung,
      tabelleUmrandungStaerke,
      tabelleUmrandungFarbe,
      tabelleHintergrundFarbe,
      tabelleKopfzeileEffekte,
      tabelleKopfzeileSchriftFarbe,
      tabelleKopfHintergrundFarbe,

      // Fusszeile
      fusszeileSpaltenlayout,
      fusszeileTexte,
      fusszeileNummerierung,
      fusszeileTexteGroesse,
      fusszeileTexteFarbe

    } = value;

    const tmpAbstandOben = abstandOben || padding?.top;
    const tmpAbstandUnten = abstandUnten || padding?.bottom;
    const tmpAbstandRechts = abstandRechts || padding?.right;
    const tmpAbstandLinks = abstandLinks || padding?.left;

    return new ClientElementLayout(
      layout,
      ueberschriftSchriftgroesse,
      textSchriftgroesse,
      ueberschriftSchriftart,
      textSchriftart,
      tmpAbstandOben,
      tmpAbstandUnten,
      tmpAbstandRechts,
      tmpAbstandLinks,
      abstandAbsatz,
      abstandVorUeberschrift,
      abstandSpalten,
      textIstFett,
      textIstUnterstrichen,
      textIstKursiv,
      ueberschriftIstFett,
      ueberschriftIstUnterstrichen,
      ueberschriftIstKursiv,
      textSchriftFarbe,
      ueberschriftSchriftFarbe,
      ueberschriftHintergrundFarbe,
      ueberschriftAbstand,
      ueberschriftUnterstrichFarbe,
      ueberschriftUnterstrichStaerke,
      ueberschriftAusrichtung,
      ueberschriftSchrifteffekte,

      logo,
      breiteLogo,
      platzierungLogo,
      kopfzeileText,
      kopfzeileTextGroesse,
      kopfzeileTextFarbe,
      kopfzeilePositionierung,

      formularHintergrundfarbe,
      formularRandPosition,
      formularRandStaerke,
      formularRandRadius,
      formularRandFarbe,

      tabelleUmrandung,
      tabelleUmrandungStaerke,
      tabelleUmrandungFarbe,
      tabelleHintergrundFarbe,
      tabelleKopfzeileEffekte,
      tabelleKopfzeileSchriftFarbe,
      tabelleKopfHintergrundFarbe,

      fusszeileSpaltenlayout,
      fusszeileTexte,
      fusszeileNummerierung,
      fusszeileTexteGroesse,
      fusszeileTexteFarbe
    );
  }

  get isValidLayout() {
    return Layout.isValidState(this.layout);
  }

  get isValidUeberschriftSchriftgroesse() {
    return typeof this.ueberschriftSchriftgroesse === 'number'
      && this.ueberschriftSchriftgroesse >= 0;
  }

  get isValidTextSchriftgroesse() {
    return typeof this.textSchriftgroesse === 'number'
      && this.textSchriftgroesse >= 0;
  }

  get isValidUeberschriftSchriftart() {
    return typeof this.ueberschriftSchriftart === 'string'
      && this.ueberschriftSchriftart.length > 0;
  }

  get isValidTextSchriftart() {
    return typeof this.textSchriftart === 'string'
      && this.textSchriftart.length > 0;
  }

  get isValidPadding() {
    return this.padding
      && typeof this.padding.top === 'number'
      && this.padding.top >= 0
      && typeof this.padding.bottom === 'number'
      && this.padding.bottom >= 0
      && typeof this.padding.right === 'number'
      && this.padding.right >= 0
      && typeof this.padding.left === 'number'
      && this.padding.left >= 0;
  }

  get isValidAbstandAbsatz() {
    return AbstandAbsatz.isValidState(this.abstandAbsatz);
  }

  get isValidAbstandVorUeberschrift() {
    return typeof this.abstandVorUeberschrift === 'number'
      && this.abstandVorUeberschrift >= 0;
  }

  get isValidAbstandSpalten() {
    return typeof this.abstandSpalten === 'number'
      && this.abstandSpalten >= 0;
  }

  get isValidTextIstFett() {
    return typeof this.textIstFett === 'number'
      && this.textIstFett >= 0;
  }

  get isValidTextIstUnterstrichen() {
    return typeof this.textIstUnterstrichen === 'number'
      && this.textIstUnterstrichen >= 0;
  }

  get isValidTextIstKursiv() {
    return typeof this.textIstKursiv === 'number'
      && this.textIstKursiv >= 0;
  }

  get isValidUeberschriftIstFett() {
    return typeof this.ueberschriftIstFett === 'number'
      && this.ueberschriftIstFett >= 0;
  }

  get isValidUeberschriftIstUnterstrichen() {
    return typeof this.ueberschriftIstUnterstrichen === 'number'
      && this.ueberschriftIstUnterstrichen >= 0;
  }

  get isValidUeberschriftIstKursiv() {
    return typeof this.ueberschriftIstKursiv === 'number'
      && this.ueberschriftIstKursiv >= 0;
  }

  isValidColor(color) {
    const colorRegex = /^#[0-9A-F]{6}$/i;
    return colorRegex.test(color);
  }


  get isValidTextSchriftFarbe() {
    return typeof this.textSchriftFarbe === 'string'
      && this.textSchriftFarbe.length > 0
      && this.isValidColor(this.textSchriftFarbe);
  }

  get isValidUeberschriftSchriftFarbe() {
    return typeof this.ueberschriftSchriftFarbe === 'string'
      && this.ueberschriftSchriftFarbe.length > 0
      && this.isValidColor(this.ueberschriftSchriftFarbe);
  }

  get isValidUeberschriftHintergrundFarbe() {
    return typeof this.ueberschriftHintergrundFarbe === 'string'
      && this.ueberschriftHintergrundFarbe.length > 0
      && this.isValidColor(this.ueberschriftHintergrundFarbe);
  }

  get isValidUeberschriftAbstand() {
    return AbstandAbsatz.isValidState(this.ueberschriftAbstand);
  }

  get isValidUeberschriftUnterstrichFarbe() {
    return typeof this.ueberschriftUnterstrichFarbe === 'string'
      && this.ueberschriftUnterstrichFarbe.length > 0
      && this.isValidColor(this.ueberschriftUnterstrichFarbe);
  }

  get isValidUeberschriftUnterstrichStaerke() {
    return typeof this.ueberschriftUnterstrichStaerke === 'number'
      && this.ueberschriftUnterstrichStaerke >= 0;
  }

  get isValidUeberschriftAusrichtung() {
    return Ausrichtung.isValidState(this.ueberschriftAusrichtung);
  }

  get isValidUeberschriftSchrifteffekte() {
    return Array.isArray(this.ueberschriftSchrifteffekte)
      && this.ueberschriftSchrifteffekte.every(
        effect => typeof effect === 'string'
          && Schrifteffekt.isValidState(effect)
      );
  }

  get isValidLogo() {
    return typeof this.logo === 'string';
  }

  get isValidBreiteLogo() {
    return typeof this.breiteLogo === 'number'
      && this.breiteLogo >= 0;
  }

  get isValidPlatzierungLogo() {
    return PlatzierungDesLogos.isValidState(this.platzierungLogo);
  }

  get isValidKopfzeileText() {
    return typeof this.kopfzeileText === 'string';
  }

  get isValidKopfzeileTextGroesse() {
    return typeof this.kopfzeileTextGroesse === 'number'
      && this.kopfzeileTextGroesse >= 0;
  }

  get isValidKopfzeileTextFarbe() {
    return typeof this.kopfzeileTextFarbe === 'string'
      && this.kopfzeileTextFarbe.length > 0
      && this.isValidColor(this.kopfzeileTextFarbe);
  }

  get isValidKopfzeilePositionierung() {
    return KopfzeilePositionierung.isValidState(this.kopfzeilePositionierung);
  }

  get isValidFormularHintergrundfarbe() {
    return typeof this.formularHintergrundfarbe === 'string'
      && this.formularHintergrundfarbe.length > 0
      && this.isValidColor(this.formularHintergrundfarbe);
  }

  get isValidFormularRandPosition() {
    return Array.isArray(this.formularRandPosition)
      && this.formularRandPosition.every(
        position => typeof position === 'string'
          && RandPosition.isValidState(position)
      );
  }

  get isValidFormularRandStaerke() {
    return typeof this.formularRandStaerke === 'number'
      && this.formularRandStaerke >= 0;
  }

  get isValidFormularRandRadius() {
    return typeof this.formularRandRadius === 'number'
      && this.formularRandRadius >= 0;
  }

  get isValidFormularRandFarbe() {
    return typeof this.formularRandFarbe === 'string'
      && this.formularRandFarbe.length > 0
      && this.isValidColor(this.formularRandFarbe);
  }

  get isValidTabelleUmrandung() {
    return Array.isArray(this.tabelleUmrandung)
      && this.tabelleUmrandung.every(
        umrandung => typeof umrandung === 'string'
          && Umrandung.isValidState(umrandung)
      );
  }

  get isValidTabelleUmrandungStaerke() {
    return typeof this.tabelleUmrandungStaerke === 'number'
      && this.tabelleUmrandungStaerke >= 0;
  }

  get isValidTabelleUmrandungFarbe() {
    return typeof this.tabelleUmrandungFarbe === 'string'
      && this.tabelleUmrandungFarbe.length > 0
      && this.isValidColor(this.tabelleUmrandungFarbe);
  }

  get isValidTabelleHintergrundFarbe() {
    return typeof this.tabelleHintergrundFarbe === 'string'
      && this.tabelleHintergrundFarbe.length > 0
      && this.isValidColor(this.tabelleHintergrundFarbe);
  }

  get isValidTabelleKopfzeileEffekte() {
    return Array.isArray(this.tabelleKopfzeileEffekte)
      && this.tabelleKopfzeileEffekte.every(
        effect => typeof effect === 'string'
          && Schrifteffekt.isValidState(effect)
      );
  }

  get isValidTabelleKopfzeileSchriftFarbe() {
    return typeof this.tabelleKopfzeileSchriftFarbe === 'string'
      && this.tabelleKopfzeileSchriftFarbe.length > 0
      && this.isValidColor(this.tabelleKopfzeileSchriftFarbe);
  }

  get isValidTabelleKopfHintergrundFarbe() {
    return typeof this.tabelleKopfHintergrundFarbe === 'string'
      && this.tabelleKopfHintergrundFarbe.length > 0
      && this.isValidColor(this.tabelleKopfHintergrundFarbe);
  }

  get isValidFusszeileSpaltenlayout() {
    return FusszeileSpaltenlayout.isValidState(this.fusszeileSpaltenlayout);
  }

  get isValidFusszeileTexte() {
    return Array.isArray(this.fusszeileTexte)
      && this.fusszeileTexte.every(
        text => typeof text === 'string'
      );
  }

  get isValidFusszeileNummerierung() {
    return Ausrichtung.isValidState(this.fusszeileNummerierung)
      || this.fusszeileNummerierung === 'keine';
  }

  get isValidFusszeileTexteGroesse() {
    return typeof this.fusszeileTexteGroesse === 'number'
      && this.fusszeileTexteGroesse >= 0;
  }

  get isValidFusszeileTexteFarbe() {
    return typeof this.fusszeileTexteFarbe === 'string'
      && this.fusszeileTexteFarbe.length > 0
      && this.isValidColor(this.fusszeileTexteFarbe);
  }

  get isValid() {
    return this.isValidLayout
      && this.isValidUeberschriftSchriftgroesse
      && this.isValidTextSchriftgroesse
      && this.isValidUeberschriftSchriftart
      && this.isValidTextSchriftart
      && this.isValidPadding
      && this.isValidAbstandAbsatz
      && this.isValidAbstandVorUeberschrift
      && this.isValidAbstandSpalten
      && this.isValidTextIstFett
      && this.isValidTextIstUnterstrichen
      && this.isValidTextIstKursiv
      && this.isValidUeberschriftIstFett
      && this.isValidUeberschriftIstUnterstrichen
      && this.isValidUeberschriftIstKursiv
      && this.isValidTextSchriftFarbe
      && this.isValidUeberschriftSchriftFarbe
      && this.isValidUeberschriftHintergrundFarbe
      && this.isValidUeberschriftAbstand
      && this.isValidUeberschriftUnterstrichFarbe
      && this.isValidUeberschriftUnterstrichStaerke
      && this.isValidUeberschriftAusrichtung
      && this.isValidUeberschriftSchrifteffekte
      && this.isValidLogo
      && this.isValidBreiteLogo
      && this.isValidPlatzierungLogo
      && this.isValidKopfzeileText
      && this.isValidKopfzeileTextGroesse
      && this.isValidKopfzeileTextFarbe
      && this.isValidKopfzeilePositionierung
      && this.isValidFormularHintergrundfarbe
      && this.isValidFormularRandPosition
      && this.isValidFormularRandStaerke
      && this.isValidFormularRandRadius
      && this.isValidFormularRandFarbe
      && this.isValidTabelleUmrandung
      && this.isValidTabelleUmrandungStaerke
      && this.isValidTabelleUmrandungFarbe
      && this.isValidTabelleHintergrundFarbe
      && this.isValidTabelleKopfzeileEffekte
      && this.isValidTabelleKopfzeileSchriftFarbe
      && this.isValidTabelleKopfHintergrundFarbe
      && this.isValidFusszeileSpaltenlayout
      && this.isValidFusszeileTexte
      && this.isValidFusszeileNummerierung
      && this.isValidFusszeileTexteGroesse
      && this.isValidFusszeileTexte
      && this.isValidFusszeileTexteFarbe;
  }
}
