export default class KopfzeilePositionierung {
  static ALLE_SEITEN = 'ALLE_SEITEN';

  static NUR_ERSTE_SEITE = 'NUR_ERSTE_SEITE';

  static isValidState(state) {
    const states = [
      this.ALLE_SEITEN, this.NUR_ERSTE_SEITE
    ];
    return states.includes(state);
  }

  static isStateAlleSeiten(state) {
    return state === this.ALLE_SEITEN;
  }

  static isStateNurErsteSeite(state) {
    return state === this.NUR_ERSTE_SEITE;
  }
}
