export default class Ausrichtung {
  static LINKS = 'links';

  static RECHTS = 'rechts';

  static ZENTRIERT = 'zentriert';

  static isValidState(ausrichtung) {
    return (
      this.isLinks(ausrichtung)
      || this.isRechts(ausrichtung)
      || this.isZentriert(ausrichtung)
    );
  }

  static isLinks(ausrichtung) {
    return ausrichtung === this.LINKS;
  }

  static isRechts(ausrichtung) {
    return ausrichtung === this.RECHTS;
  }

  static isZentriert(ausrichtung) {
    return ausrichtung === this.ZENTRIERT;
  }
}
