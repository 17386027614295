export default class RandPosition {
  static BORDER_TOP = 'BORDER_TOP';

  static BORDER_RIGHT = 'BORDER_RIGHT';

  static BORDER_BOTTOM = 'BORDER_BOTTOM';

  static BORDER_LEFT = 'BORDER_LEFT';

  static isValidState(state) {
    const states = [
      this.BORDER_TOP, this.BORDER_RIGHT, this.BORDER_BOTTOM, this.BORDER_LEFT
    ];
    return states.includes(state);
  }

  static isBorderTop(state) {
    return state === this.BORDER_TOP;
  }

  static isBorderRight(state) {
    return state === this.BORDER_RIGHT;
  }

  static isBorderBottom(state) {
    return state === this.BORDER_BOTTOM;
  }

  static isBorderLeft(state) {
    return state === this.BORDER_LEFT;
  }
}

