export default class PlatzierungDesLogos {
  static LEFT = 'left';

  static RIGHT = 'right';

  static isValidState(state) {
    const states = [
      this.LEFT, this.RIGHT
    ];
    return states.includes(state);
  }

  static isLeft(state) {
    return state === this.LEFT;
  }

  static isRight(state) {
    return state === this.RIGHT;
  }
}
