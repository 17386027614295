export default class Umrandung {
  static KEINE = 'KEINE';

  static DURCHGEHEND = 'DURCHGEHEND';

  static AUSSEN = 'AUSSEN';

  static HORIZONTAL = 'HORIZONTAL';

  static VERTIKAL = 'VERTIKAL';

  static AUSSEN_UND_VERTIKAL = 'AUSSEN_UND_VERTIKAL';

  static AUSSEN_UND_HORIZONTAL = 'AUSSEN_UND_HORIZONTAL';

  static VERTIKAL_UND_HORIZONTAL = 'VERTIKAL_UND_HORIZONTAL';

  static isValidState(umrandung) {
    return (
      this.isKeine(umrandung)
      || this.isDurchgehend(umrandung)
      || this.isAussen(umrandung)
      || this.isAussenUndVertikal(umrandung)
      || this.isAussenUndHorizontal(umrandung)
      || this.isVertikal(umrandung)
      || this.isHorizontal(umrandung)
      || this.isVertikalUndHorizontal(umrandung)
    );
  }

  static isKeine(umrandung) {
    return umrandung === this.KEINE;
  }

  static isDurchgehend(umrandung) {
    return umrandung === this.DURCHGEHEND;
  }

  static isAussen(umrandung) {
    return umrandung === this.AUSSEN;
  }

  static isHorizontal(umrandung) {
    return umrandung === this.HORIZONTAL;
  }

  static isVertikal(umrandung) {
    return umrandung === this.VERTIKAL;
  }

  static isAussenUndVertikal(umrandung) {
    return umrandung === this.AUSSEN_UND_VERTIKAL;
  }

  static isAussenUndHorizontal(umrandung) {
    return umrandung === this.AUSSEN_UND_HORIZONTAL;
  }

  static isVertikalUndHorizontal(umrandung) {
    return umrandung === this.VERTIKAL_UND_HORIZONTAL;
  }

  static getUmrandungTable(umrandung, color, thickness, radius) {
    if(umrandung.includes(this.AUSSEN)) {
      return {
        border: `${thickness}pt solid ${color}`,
        borderRadius: `${radius}pt`
      };
    } else {
      return {
        border: 'none'
      };
    }
  }

  static getUmrandungTd(umrandung, color, thickness, radius) {
    if (
      umrandung.some((u) => this.isDurchgehend(u)
        || this.isVertikalUndHorizontal(u)
        || this.isAussenUndVertikal(u)
        || this.isVertikal(u))
    ) {
      return {
        borderLeft: `${thickness}pt solid ${color}`,
        borderRadius: `${radius}pt`
      };
    }

    return {
      border: 'none'
    };
  }

  static getUmrandungTr(umrandung, color, thickness, radius) {
    if (
      umrandung.some((u) => this.isDurchgehend(u)
        || this.isVertikalUndHorizontal(u)
        || this.isAussenUndHorizontal(u)
        || this.isHorizontal(u))
    ) {
      return {
        borderTop: `${thickness}pt solid ${color}`,
        borderRadius: `${radius}pt`
      };
    }
    return {
      border: 'none'
    };
  }
}
