import { Node } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { isJSON } from '@st/utils-js';
import MainSchema from '../components/prosemirror/schemas/TextContentSchema';
import ContentElement from './ContentElement';
import ElementStatus from './enums/ElementStatus';
import ElementTyp from './enums/ElementTyp';
import TextContentElementAttrs from './TextContentElementAttrs';

export default class TextContentElement extends ContentElement {
  static VALID_TYPES = [
    ElementTyp.UEBERSCHRIFT, ElementTyp.ABSCHNITT, ElementTyp.TABELLE, ElementTyp.FORMULAR
  ];

  /**
   *
   * @param {String} id
   * @param {String} state value of ElementStatus
   * @param {String} type value of ElementTyp which are Leafs (ABSCHNITT, UEBERSCHRIFT)
   * @param {TextContentElementAttrs} attrs
   * @param {Array} content ProseMirror section content
   * @param {String} choice UUID of a choice
   * @param {String} refPathId UUID
   * @param {*} externalExplanations
   * @param {*} internalExplanations
   * @param {*} preFormulatedQuestion
   * @param {Array} similarities
   */
  constructor(
    id = null,
    state = ElementStatus.MODIFIABLE,
    type = ElementTyp.ABSCHNITT,
    attrs = new TextContentElementAttrs(type),
    content = [],
    choice = [],
    refPathId = null,
    externalExplanations = '',
    internalExplanations = '',
    preFormulatedQuestion = '',
    similarities = []
  ) {
    super(id, state, type, choice, refPathId);
    this.content = content;
    this.attrs = attrs;
    this.externalExplanations = externalExplanations;
    this.internalExplanations = internalExplanations;
    this.preFormulatedQuestion = preFormulatedQuestion;
    this.similarities = similarities;
  }

  static getTextContent(content) {
    const schema = MainSchema;
    let node = schema.nodes.section.create().toJSON();
    node.content = content;

    node = Node.fromJSON(schema, node);

    const state = EditorState.create({
      doc: node,
      schema
    });
    return state.doc.textContent;
  }

  /**
   * Creates TextContentElement from JSON. If keys are missing then it will use the default values.
   * @param {JSON} value
   * @returns null if invalid
   */
  static fromJSON(value, similarities = []) {
    if (value instanceof TextContentElement) return value;
    if (!isJSON(value)) return null;

    const attrs = TextContentElementAttrs.fromJSON({ ...value.attrs, type: value.type });

    const {
      id = null,
      state = ElementStatus.MODIFIABLE,
      type = ElementTyp.ABSCHNITT,
      content = [],
      choice = [],
      refPathId = null,
      externalExplanations = '',
      internalExplanations = '',
      preFormulatedQuestion = ''
    } = value;

    return new TextContentElement(
      id,
      state,
      type,
      attrs,
      content,
      choice,
      refPathId,
      externalExplanations,
      internalExplanations,
      preFormulatedQuestion,
      similarities
    );
  }

  static createValidJSON(value) {
    return TextContentElement.fromJSON(value).toJSON();
  }

  /**
   * @returns TextContentElement in JSON representation
   */
  toJSON() {
    const { attrs } = this;
    let localAttrs = attrs;
    if (localAttrs instanceof TextContentElementAttrs) {
      localAttrs = attrs.toJSON();
    }

    return {
      id: this.id,
      state: this.state,
      type: this.type,
      attrs: localAttrs,
      content: this.content,
      choice: this.choice,
      refPathId: this.refPathId,
      externalExplanations: this.externalExplanations,
      internalExplanations: this.internalExplanations,
      preFormulatedQuestion: this.preFormulatedQuestion,
      textContent: this.textContent
    };
  }

  get textContent() {
    return TextContentElement.getTextContent(this.content);
  }

  get validContent() {
    return !!this.content
    && Array.isArray(this.content)
    && this.content.length > 0
    && this.content.every((contentItem) => contentItem.type === 'paragraph');
  }

  get validType() {
    return !!this.type && TextContentElement.VALID_TYPES.includes(this.type);
  }

  get validAttrs() {
    return !!this.attrs
      && this.attrs instanceof TextContentElementAttrs
      && this.attrs.valid;
  }

  get validSimilarities() {
    return Array.isArray(this.similarities);
  }

  get valid() {
    const {
      validId,
      validState,
      validContent,
      validChoice,
      validRefPathId,
      validSimilarities
    } = this;
    // TODO
    // const validTextContent = !!this.textContent
    //   && typeof this.textContent === 'string'
    //   && !!this.textContent.trim();

    const valid = validId
      && validState
      && this.validType
      && this.validAttrs
      && validContent
      && validChoice
      // && validTextContent
      && validSimilarities
      && validRefPathId;

    return valid;
  }
}
