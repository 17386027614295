import { uuid, validate } from '../utils/uuid';
import ElementStatus from './enums/ElementStatus';
import PersistObj from './PersistObj';

export default class ContentElement extends PersistObj {
  /**
   *
   * @param {String} id UUID
   * @param {String} state value of ElementStatus
   * @param {String} type value of ElementTyp
   * @param {String} choice UUID
   * @param {String} refPathId UUID
   */
  constructor(
    id = null,
    state = ElementStatus.MODIFIABLE,
    type = '',
    choice = [],
    refPathId = null
  ) {
    super(id);
    this.state = state;
    this.type = type;
    this.choice = choice;
    let localRefPathId = refPathId;
    if (!validate(localRefPathId)) {
      localRefPathId = uuid();
    }
    this.refPathId = localRefPathId;
  }

  get validState() {
    return !!this.state && typeof this.state === 'string' && ElementStatus.isValidState(this.state);
  }

  get validType() {
    return !!this.type && typeof this.type === 'string' && !!this.type.trim();
  }

  get validChoice() {
    if (this.choice?.length === 0) return true;

    const choiceIsStringAndValid = !!this.choice && typeof this.choice === 'string' && validate(this.choice);

    const choiceIsArrayAndValid = Array.isArray(this.choice) && this.choice.every((item) => typeof item === 'string' && validate(item));

    return choiceIsStringAndValid || choiceIsArrayAndValid;
  }

  get validRefPathId() {
    return !!this.refPathId && typeof this.refPathId === 'string' && validate(this.refPathId);
  }
}
